import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class EnvService {

  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  public apiUrl = "https://api.ms-dev.vertt.ch";

  // Google Api Key
  public googleApiKey = "AIzaSyBW9XcQ4u-NW_0zj_Itx3L0PR3rBVvDPx8";

  constructor() {
  }

}
