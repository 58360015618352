import { Injectable } from "@angular/core";
import { BackendService } from "../backend";

@Injectable({
  providedIn: "root"
})
export class DashboardService {

  constructor(private backendService: BackendService) { }

  getRevenue(period: { from: number; to: number }) {
    return this.backendService.getBackend(`/earnings/revenue?from=${period.from}&to=${period.to}`);
  }

  getActiveRides() {
    return this.backendService.getBackend("/ride/stats/active");
  }

  getRidesStats(period: { from: number; to: number }) {
    return this.backendService.getBackend(`/ride/stats/by-status?from=${period.from}&to=${period.to}`);
  }

  getOnlineClients() {
    return this.backendService.getBackend("/user/clients");
  }

  getDriverDetails(id: string) {
    return this.backendService.getBackend("/driver/" + id + "/profile");
  }

  getPassengerDetails(id: string) {
    return this.backendService.getBackend("/passenger/" + id + "/profile");
  }

  getTopDriversTimeOnline(query: string) {
    return this.backendService.getBackend(`/driver/time-tracking/top?${query}`);
  }

  getTopDriversAcceptanceRate(query: string) {
    return this.backendService.getBackend(`/driver/acceptance-rate/top?${query}`);
  }

  getTotalDriversByStatus(period: { from: number; to: number }) {
    return this.backendService.getBackend(`/driver/by-status?from=${period.from}&to=${period.to}`);
  }

  getStatsByCarCategory(period: { from: number; to: number }) {
    return this.backendService.getBackend(`/ride/stats/by-car-type?from=${period.from}&to=${period.to}`);
  }

  getTotalApprovedDriversByCarCategory(period: { from: number; to: number }) {
    return this.backendService.getBackend(`/driver/by-car-type?from=${period.from}&to=${period.to}`);
  }

  getTopClientsByRideStatus(query: string) {
    return this.backendService.getBackend(`/ride/stats/clients-by-status?${query}`);
  }

  getHeatmapData(query: string) {
    return this.backendService.getBackend(`/ride/stats/heatmap-points?${query}`);
  }
}
