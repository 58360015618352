declare var google: any;

export class MapUtils {
  static createPinStart(): HTMLElement {
    return new google.maps.marker.PinElement({
      background: "#2eceb7",
      borderColor: "#2eceb7",
      glyphColor: "#ffffff",
    }).element;
  }

  static createPinEnd(): HTMLElement {
    return new google.maps.marker.PinElement({
      background: "#2eceb7",
      borderColor: "#2eceb7",
      glyphColor: "#2eceb7",
    }).element;
  }

  static createPin(): HTMLElement {
    return new google.maps.marker.PinElement({
      background: "#2eceb7",
      borderColor: "#2eceb7",
      glyphColor: "#ffffff",
    }).element;
  }

  static createCarMarkerSvg(): SVGSVGElement {
    const carImageSvgString =
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="34" viewBox="9.364 0.45 15.47 33.79"><path fill="#da291c" stroke="#0a0a0a" d="M12.66 33.54c-2.85-1.38-3.57-3.63-3.21-10.11.12-2.43.27-8.04.33-12.54.03-5.25.18-8.34.39-8.76C10.92.75 12.12.45 17.1.45s6.18.3 6.93 1.68c.21.39.36 3.57.39 8.91.06 4.59.21 10.2.33 12.54.36 6.36-.39 8.61-3.27 9.99-1.95.9-6.93.9-8.82-.03zm8.88-8.19c1.14-.36 1.56-.63 1.56-1.05 0-.3.15-.6.3-.66.51-.15 0-9.24-.51-9.24-.3 0-.39.57-.39 2.22 0 2.61.33 2.43-4.95 2.91-2.58.21-3.87.21-4.47-.03-.48-.18-.93-.24-.99-.18-.18.18-.99 4.32-.99 5.01 0 .45 1.17.93 3.75 1.59 1.08.27 5.07-.06 6.69-.57zM12.99 7.23c.21-.87 2.31-1.32 5.31-1.14 1.41.09 2.82.21 3.15.27.51.09.6-.09.69-1.29.09-1.35.09-1.38-1.14-1.74-1.74-.51-6.6-.45-7.95.09-1.14.48-1.17.66-.87 3 .21 1.44.54 1.8.81.81z" /></svg>';
    const parser = new DOMParser();
    const pinSvg = parser.parseFromString(
      carImageSvgString,
      "image/svg+xml"
    ).documentElement;
    return pinSvg as unknown as SVGSVGElement;
  }
}
