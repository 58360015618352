import { Component, OnInit } from "@angular/core";
import { OnlineClientsService } from "../data/online-clients/online-clients.service";
import { MapUtils } from "app/shared/map-utils";

// tslint:disable:no-magic-numbers

declare var google: any;
declare var $: any;

@Component({
  selector: "app-online-clients",
  templateUrl: "./online-clients.component.html",
  styleUrls: ["./online-clients.component.scss"]
})
export class OnlineClientsComponent implements OnInit {
  map: any;
  marker: any;
  markersArray = [];
  onlineClients: any[] = [];
  selectedFilter = "All types";
  displayType = "list";
  interval: any;
  clientDetails: any = {};
  carTypes = [
    { id: 0, name: "Vertt" },
    { id: 1, name: "Premium" },
    { id: 2, name: "Lux" },
  ];
  spinner = false;

  constructor(
    private onlineClientsService: OnlineClientsService,
  ) { }

  async ngOnInit() {
    // this.getOnlineClients();
    // this.initMap(this.filterDrivers(this.onlineClients));
    // this.autoReloadOnlineClients();
  }

  autoReloadOnlineClients() {
    this.interval = setInterval(() => {
      this.getOnlineClients();
      this.fillMap(this.filterDrivers(this.onlineClients));
    }, 8000);
  }

  async reloadOnlineClients() {
    this.getOnlineClients();
    this.fillMap(this.filterDrivers(this.onlineClients));
  }

  getOnlineClients() {
    this.spinner = true;
    this.onlineClientsService.getOnlineClients().subscribe(clients => {
      console.log("Online clients: ", clients);
      this.onlineClients = clients;
      this.spinner = false;
    });
  }

  filterDrivers(clients) {
    return clients.filter(client => client.type === "driver");
  }

  changeDisplayType(displayType) {
    this.displayType = displayType;
    this.reloadOnlineClients();
  }

  getClientDetails(client: any) {
    if (client.type === "passenger") {
      this.onlineClientsService.getPassengerDetails(client.clientId).subscribe(res => {
        this.clientDetails = res;
        Object.assign(this.clientDetails, {
          clientType: client.type,
          carTypes: client.carTypes,
          paymentMethods: client.paymentMethods
        });
      });
    } else {
      this.onlineClientsService.getDriverDetails(client.clientId).subscribe(res => {
        this.clientDetails = res;
        Object.assign(this.clientDetails, {
          clientType: client.type,
          carTypes: client.carTypes,
          paymentMethods: client.paymentMethods
        });
      });
    }
  }

  initMap(clients) {
    this.map = new google.maps.Map(document.getElementById("map"), {
      // center: { lat: 41.995493, lng: 21.42239 },   // Skopje
      center: { lat: 47.3863168, lng: 8.4867095 },    // Zurich
      zoom: 15,
      mapId: "fb5b626ade09170a"
    });
    this.fillMap(clients);
  }

  clearMarkers() {
    // Clear markers on map
    for (const marker of this.markersArray) {
      marker.setMap(null);
    }
    // Empty markers array
    this.markersArray = [];
  }

  fillMap(clients) {
    this.clearMarkers();

    for (const client of clients) {
      if (client.location) {
        // let angle = -Number(client.direction);
        // let x = Math.sin(-angle * Math.PI / 180) * 0.5 + 0.5;
        // let y = -(Math.cos(-angle * Math.PI / 180) * 0.5 - 0.5);

        this.marker = new google.maps.marker.AdvancedMarkerElement({
          position: { lat: Number(client.location.lat), lng: Number(client.location.lng) },
          map: this.map,
          content: MapUtils.createCarMarkerSvg(),
        });

        this.markersArray.push(this.marker);

        this.marker.addListener("click", () => {
          this.getClientDetails(client);
          $("#clientDetails").modal("show");
        });
        this.marker.setMap(this.map);
      }
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    $(".modal .close").click();
  }

}
